import axios from 'axios';
import { showToast } from 'vant';
import router from '@/router';
import store from '@/store';  // 导入 store

// 使用环境变量中的 API URL
const baseURL = process.env.VUE_APP_API_URL;
console.log('API baseURL:', baseURL);

const api = axios.create({
  baseURL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// 请求拦截器
api.interceptors.request.use(
  (config) => {
    // 添加请求日志
    console.log('发送请求:', {
      url: config.url,
      method: config.method,
      params: config.params,
      data: config.data,
      headers: config.headers
    });
    // 从 Vuex 获取 token
    const token = store.getters['user/token'];
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.error('请求错误:', error);
    showToast({
      message: '请求发送失败',
      type: 'fail'
    });
    return Promise.reject(error);
  }
);

// 响应拦截器
api.interceptors.response.use(
  (response) => {
    const res = response.data;
    
    // 添加响应日志
    console.log('收到响应:', {
      url: response.config.url,
      status: response.status,
      data: response.data
    });
    
    // 处理 401 认证失败的情况
    if (res.code === 401) {
      showToast({
        message: '登录已过期，请重新登录',
        type: 'warning'
      });
      
      // 清除用户信息和 token
      store.dispatch('user/logout');
      
      // 如果不是登录页面，则重定向到登录页
      if (router.currentRoute.value.name !== 'Login') {
        router.push({ 
          name: 'Login',
          query: { redirect: router.currentRoute.value.fullPath }
        });
      }
      
      return Promise.reject(new Error(res.msg || '认证失败'));
    }
    
    if (res.code === 200) {
      return res;
    } else {
      showToast({
        message: res.message || '请求失败',
        type: 'fail'
      });
      return Promise.reject(new Error(res.message || '请求失败'));
    }
  },
  (error) => {
    console.error('响应错误:', {
      url: error.config?.url,
      method: error.config?.method,
      params: error.config?.params,
      data: error.config?.data,
      status: error.response?.status,
      statusText: error.response?.statusText,
      responseData: error.response?.data,
      message: error.message
    });

    // 处理 401 错误响应
    if (error.response?.status === 401) {
      showToast({
        message: '登录已过期，请重新登录',
        type: 'warning'
      });
      
      // 清除用户信息和 token
      store.dispatch('user/logout');
      
      // 如果不是登录页面，则重定向到登录页
      if (router.currentRoute.value.name !== 'Login') {
        router.push({ 
          name: 'Login',
          query: { redirect: router.currentRoute.value.fullPath }
        });
      }
    }
    
    return Promise.reject(new Error(error.response?.data?.message || '请求失败'));
  }
);

export default api;
