<template>
  <div id="app">
    <!-- 其他内容 -->
    <router-view></router-view>
    <!-- <van-tabbar v-model="active" class="bottom-tabbar">
      <van-tabbar-item icon="home-o" to="/">首页</van-tabbar-item>
      <van-tabbar-item icon="user-o" to="/admin/User">我的</van-tabbar-item>
    </van-tabbar> -->
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from 'vant';

export default {
  name: 'App',
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
  data() {
    return {
      active: 0, // 默认选中首页
    };
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
/* .bottom-tabbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
} */
</style>
