import { userApi } from '@/services/userApi'  // 添加这行导入语句

// 安全地解析 JSON
const safeJSONParse = (str, defaultValue = null) => {
  if (!str) return defaultValue;
  try {
    return JSON.parse(str);
  } catch (e) {
    console.error('JSON parse error:', e);
    return defaultValue;
  }
};

// 获取初始用户信息
const getInitialUserInfo = () => {
  const storedInfo = localStorage.getItem('userInfo');
  return safeJSONParse(storedInfo, {});
};

export default {
  namespaced: true,  // 启用命名空间
  
  state: () => ({
    token: localStorage.getItem('token') || '',
    userInfo: getInitialUserInfo()
  }),
  
  mutations: {
    SET_TOKEN(state, token) {
      // 设置 token 到 state
      state.token = token || '';
      // 同时更新 localStorage
      if (token) {
        localStorage.setItem('token', token);
      } else {
        localStorage.removeItem('token');
      }
    },
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo || {};
      if (userInfo) {
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
      } else {
        localStorage.removeItem('userInfo');
      }
    },
    CLEAR_USER_DATA(state) {
      state.token = '';
      state.userInfo = {};
      localStorage.removeItem('token');
      localStorage.removeItem('userInfo');
    }
  },
  
  actions: {
    // 登录
    async login({ commit }, loginData) {
      try {
        const response = await userApi.login(loginData);
        console.log('Login API response:', response);
        
        if (response) {
          const token = response.token || response.data;
          const userInfo = response.user || response.loginUser.user;
        
          if (token) {
            console.log('Setting token:', token);
            commit('SET_TOKEN', token);
          }
          
          if (userInfo) {
            console.log('Setting user info:', userInfo);
            commit('SET_USER_INFO', userInfo);
          }
          
          return response;
        }
        throw new Error('Invalid response data');
      } catch (error) {
        console.error('Login error:', error);
        throw error;
      }
    },
    
    // 退出登录
    async logout({ commit }) {
      try {
        await userApi.logout();
      } catch (error) {
        console.error('Logout API error:', error);
      } finally {
        commit('CLEAR_USER_DATA');
      }
    },
  },
  
  getters: {
    token: state => state.token || '',
    userInfo: state => state.userInfo || {},
    isLoggedIn: state => !!state.token,
    userName: state => state.userInfo?.userName || '',
    userRole: state => state.userInfo?.roles?.[0]?.roleName || ''
  }
}
