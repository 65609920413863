import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant'
import 'vant/lib/index.css'
import BaiduMap from 'vue-baidu-map-3x'
import store from './store'

const app = createApp(App)

app.use(router)
   .use(Vant)
   .use(store)
   .provide('router', router)
   .use(BaiduMap, {
     ak: 'jgzk8xXYwd6VcQaa4Z1FgoU4Ye7FjL02'  // 这里填入您的百度地图 API 密钥
   })

// 生产环境下禁用 Vue 的错误提示
if (process.env.NODE_ENV === 'development') {
  app.config.errorHandler = (err) => {
    console.error(err);
  }
}

// 添加全局错误处理
app.config.errorHandler = (err, vm, info) => {
  console.error('Global error:', err);
  console.error('Component:', vm);
  console.error('Info:', info);
};

app.mount('#app')
