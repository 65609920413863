import { createRouter, createWebHistory } from 'vue-router'
import LayoutWithTabbar from '@/components/admin/LayoutWithTabbar.vue'
import store from '@/store'  // 导入 store

const routes = [
  {
    path: '/',
    component: LayoutWithTabbar,
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/components/HomePage.vue')
      },
      {
        path: 'admin/User',
        name: 'User',
        component: () => import('@/components/admin/User.vue')
      }
    ]
  },
  {
    path: '/admin/inspection',
    name: 'InspectionList',
    component: () => import('@/components/admin/InspectionList.vue')
  },
  {
    path: '/admin/task',
    name: 'Task',
    component: () => import('@/components/admin/Task.vue')
  },
  {
    path: '/admin/documents',
    name: 'DocumentList',
    component: () => import('@/components/admin/DocumentList.vue')
  },
  {
    path: '/document/:workflowId',
    name: 'DocumentDetail',
    component: () => import('@/components/admin/DocumentDetail.vue'),
    props: true
  },
  {
    path: '/signed-document/:workflowId',
    name: 'SignedDocumentDetail',
    component: () => import('@/components/admin/SignedDocumentDetail.vue'),
    props: true
  },
  {
    path: '/admin/stamps',
    name: 'StampList',
    component: () => import('@/components/admin/StampList.vue')
  },
  {
    path: '/admin/StampDetail/:id',
    name: 'StampDetail',
    component: () => import('@/components/admin/StampDetail.vue')
  },
  {
    path: '/admin/training',
    name: 'Training',
    component: () => import('@/components/admin/Training.vue')
  },
  {
    path: '/admin/license',
    name: 'License',
    component: () => import('@/components/admin/License.vue')
  },
  {
    path: '/admin/license/:id',
    name: 'LicenseDetail',
    component: () => import('@/components/admin/LicenseDetail.vue')
  },
  {
    path: '/admin/license/add',
    name: 'AddLicense',
    component: () => import('@/components/admin/AddLicense.vue')
  },
  {
    path: '/admin/inspection/add',
    name: 'AddInspection',
    component: () => import('@/components/admin/AddInspection.vue')
  },
  {
    path: '/admin/inspection/:id',
    name: 'InspectionDetail',
    component: () => import('@/components/admin/InspectionDetail.vue')
  },
  {
    path: '/admin/workRecord',
    name: 'WorkRecordList',
    component: () => import('@/components/admin/WorkRecordList.vue')
  },
  {
    path: '/admin/workRecord/:id',
    name: 'WorkRecordDetail',
    component: () => import('@/components/admin/WorkRecordDetail.vue')
  },
  {
    path: '/admin/workRecord/add',
    name: 'AddWorkRecord',
    component: () => import('@/components/admin/AddWorkRecord.vue')
  },
  {
    path: '/admin/law',
    name: 'Law',
    component: () => import('@/components/admin/Law.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/components/admin/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/components/admin/Register.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/components/admin/ForgotPassword.vue')
  },
  {
    path: '/approved-stamp-detail/:id',
    name: 'ApprovedStampDetail',
    component: () => import('@/components/admin/ApprovedStampDetail.vue'),
    meta: {
      requiresAuth: true
    }
  },
  // ... 其他路由
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  const isLoggedIn = store.getters['user/isLoggedIn'];
  const token = store.getters['user/token'];
  
  console.log('Navigation guard:', {
    to,
    from,
    isLoggedIn,
    token,
    'to.name': to.name,
    'to.path': to.path
  });

  // 不需要登录的路由名单
  const publicPages = ['Login','Register','ForgotPassword'];
  const authRequired = !publicPages.includes(to.name);

  if (authRequired && !isLoggedIn) {
    console.log('需要登录，重定向到登录页');
    next({ name: 'Login' });
  } else if (to.name === 'Login' && isLoggedIn) {
    console.log('已登录，重定向到首页');
    next({ name: 'Home' });
  } else {
    console.log('正常导航');
    next();
  }
});

export default router
