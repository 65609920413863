import api from './api';

export const userApi = {
  login: (data) => api.post('/wechatLogin', data),
  getUserInfo: () => api.get('/user'),
  updateUserInfo: (data) => api.put('/user', data),
  getCode: (phoneNumber) => api.get(`system/user/code/${phoneNumber}`),
  register: (data) => api.post('/register', {
    username: data.phone,
    password: data.password,
    code: data.code
  }),
  updatePassword: (data) => api.post('system/user/password', data),
};

